import _ from 'lodash';
import React from 'react';
import Image from 'next/image';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';
import { firebaseAuth } from 'utils/firebaseConfig';

import localStore from 'utils/localStore';

import Head from 'components/Head';
import MaxWidthContainer from 'components/MaxWidthContainer';
import DesktopModalContainer from 'components/Register/DesktopModalContainer';
import MobileModalContainer from 'components/Register/MobileModalContainer';

import {
  redirectEmailLogin,
  loginHandle,
  fetchUserContent,
} from 'utils/loginFunctions';

const { useState, useEffect } = React;

const LogInMethodHighlight = () => {
  return (
    <div className="ml-auto rounded-lg bg-[#D6DDFB] px-2 py-0.5 text-xs font-bold leading-[150%] text-[#5882FF]">
      上次登入方式
    </div>
  );
};

export default function SelectLogin() {
  const [isLoginError, setIsLoginError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoginProcessing, setIsLoginProcessing] = useState(false);
  const [isLoginManually, setIsLoginManually] = useState(false);

  const [lastLoginMethod, setLastLoginMethod] = useState('');

  useEffect(() => {
    const lastSignInMethod = localStore.getLastSignInMethod();
    if (lastSignInMethod) {
      setLastLoginMethod(lastSignInMethod);
    }
  }, []);

  const auth = firebaseAuth;

  useEffect(() => {
    // check signup action
    const votingSignupAction = localStorage.getItem('voting_signup_action');
    if (votingSignupAction) {
      localStorage.removeItem('voting_signup_action');
      if (votingSignupAction === 'google') {
        loginWithGoogle();
      } else if (votingSignupAction === 'facebook') {
        loginWithFacebook();
      } else if (votingSignupAction === 'email') {
        redirectEmailLogin();
      }
    }
  }, []);

  const displayErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/too-many-requests':
        return '你的點擊次數或超出上限，請稍候再試！';

      case 'auth/account-exists-with-different-credential':
        return '你選擇的電郵地址已登記，請用使用相關社交賬號 / 電郵登入！';

      default:
        return '不好意思！遇到系統問題，請稍候再試！';
    }
  };

  const loginWithFacebook = () => {
    setIsLoginError(false);
    setIsLoginProcessing(true);
    setIsLoginManually(true);

    const auth = firebaseAuth;
    const provider = new FacebookAuthProvider();
    const redirectLink = localStorage.getItem('pageBeforeLogin') ?? '/';

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const googleToken = credential.accessToken;
        const user = result.user;
        const userToken = user.accessToken;
        fetchUserContent(userToken).then(async (res) => {
          if (res.success) {
            const { data } = res;
            localStorage.removeItem('sorra_survey_popup');
            localStore.setLastSignInMethod('facebook');
            loginHandle(data, redirectLink);
          } else {
            setIsLoginProcessing(false);
          }
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        setIsLoginProcessing(false);

        if (errorCode == 'auth/popup-closed-by-user') {
          setIsLoginError(false);
        } else {
          setIsLoginError(true);
          const displayedMsg = displayErrorMessage(errorCode);
          setErrorMsg(displayedMsg);
        }
      });
  };

  const loginWithGoogle = () => {
    setIsLoginError(false);
    setIsLoginProcessing(true);
    setIsLoginManually(true);

    const auth = firebaseAuth;
    const provider = new GoogleAuthProvider();
    const redirectLink = localStorage.getItem('pageBeforeLogin') ?? '/';

    // console.log({ redirectLink })

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const googleToken = credential.accessToken;
        const user = result.user;
        const userToken = user.accessToken;
        fetchUserContent(userToken).then(async (res) => {
          if (res.success) {
            const { data } = res;
            localStorage.removeItem('sorra_survey_popup');
            localStore.setLastSignInMethod('google');
            loginHandle(data, redirectLink);
          } else {
            setIsLoginProcessing(false);
          }
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        setIsLoginProcessing(false);

        if (errorCode == 'auth/popup-closed-by-user') {
          setIsLoginError(false);
        } else {
          setIsLoginError(true);
          const displayedMsg = displayErrorMessage(errorCode);
          setErrorMsg(displayedMsg);
        }
      });
  };

  const closeModal = () => {
    const redirectLink = localStorage.getItem('pageBeforeLogin') ?? '/';
    if (redirectLink == '/profile') {
      window.location = '/';
    } else {
      if (redirectLink.includes('/profile')) {
        window.location = '/';
      } else {
        window.location = redirectLink;
      }
    }
  };

  return (
    <div>
      <Head />

      <main>
        <DesktopModalContainer>
          <div className="relative h-full w-full">
            {isLoginProcessing ? (
              <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center bg-white">
                <img
                  className="m-[auto] h-[160px] w-[160px]"
                  src="/images/search-placeholder.gif"
                ></img>
              </div>
            ) : null}
            <div className="relative mx-[16px] mt-[16px] flex h-[48px] flex-row items-center justify-between">
              <div className="flex items-center justify-center">
                <div
                  className="absolute left-0 top-0 flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-[8px] border-[1px] border-[#E7E7E7]"
                  onClick={closeModal}
                >
                  <Image src="/svgs/close.svg" alt="" width={30} height={30} />
                </div>
              </div>
              <div></div>
              <div />
            </div>
            <div className="mt-[96px] h-[100%] flex-col justify-center px-[24px]">
              <div className="text-[30px] font-[700]">登入/註冊</div>
              <div className="mt-[16px] text-[16px] font-[500]">
                歡迎加入Sorra&#xFF0c;解鎖會員福利&#xFF01;
              </div>
              <div className="mt-[32px]">
                <button
                  className="h-[40px] w-[100%] rounded-[8px] bg-facebook text-[13px] font-[700] text-[#fff]"
                  onClick={() => loginWithFacebook()}
                >
                  <div className="flex flex-row items-center justify-start px-4">
                    <Image
                      src="/svgs/facebook.svg"
                      height={20}
                      width={21}
                      alt="search"
                      className="mr-[8px]"
                    />
                    <p>使用Facebook登入</p>
                    {lastLoginMethod === 'facebook' && <LogInMethodHighlight />}
                  </div>
                </button>
              </div>
              <div className="mt-[16px]">
                <button
                  className="h-[40px] w-[100%] rounded-[8px] border-[1px] border-[#9B9B9B] text-[14px] font-[700] text-sorra-gray-500"
                  onClick={() => loginWithGoogle()}
                >
                  <div className="flex flex-row items-center justify-start px-4">
                    <Image
                      src="/svgs/google.svg"
                      height={20}
                      width={21}
                      alt="search"
                      className="mr-[20px]"
                    />
                    <p>使用Google登入</p>
                    {lastLoginMethod === 'google' && <LogInMethodHighlight />}
                  </div>
                </button>
              </div>
              <div className="mt-[16px]">
                <button
                  className="h-[40px] w-[100%] rounded-[8px] border-[1px] border-sorra-pink text-[16px] font-[700] text-sorra-pink"
                  onClick={redirectEmailLogin}
                >
                  <div className="flex flex-row items-center justify-start px-4">
                    <Image
                      src="/svgs/mail.svg"
                      height={20}
                      width={20}
                      alt="search"
                      className="mr-[16px]"
                    />
                    <p>電郵登入/註冊</p>
                    {lastLoginMethod === 'email' && <LogInMethodHighlight />}
                  </div>
                </button>
              </div>
              <div className="mt-3 text-[14px] font-[400] text-[#9B1C1C]">
                {isLoginError ? (
                  <div className="flex flex-row items-center">
                    <Image
                      src="/svgs/error.svg"
                      width={16}
                      height={16}
                      alt=""
                      className="mr-[8px]"
                    />
                    {errorMsg}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </DesktopModalContainer>

        <MobileModalContainer>
          <div className="relative flex h-full w-full flex-col">
            {isLoginProcessing ? (
              <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center bg-white">
                <img
                  className="m-[auto] h-[80px] w-[80px]"
                  src="/images/search-placeholder.gif"
                ></img>
              </div>
            ) : null}

            <div className="relative mx-[16px] mt-[16px] flex h-[48px] flex-row items-center justify-between">
              <div className="flex items-center justify-center">
                <div
                  className="absolute left-0 top-0 flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-[8px] border-[1px] border-[#E7E7E7]"
                  onClick={closeModal}
                >
                  <Image src="/svgs/close.svg" alt="" width={30} height={30} />
                </div>
              </div>
              <div></div>
            </div>

            <div className="mt-[96px] h-[100%] flex-col justify-center px-[16px]">
              <div className="text-[30px] font-[700]">登入/註冊</div>
              <div className="mt-[16px] text-[16px] font-[500]">
                歡迎加入Sorra&#xFF0c;解鎖會員福利&#xFF01;
              </div>
              <div className="mt-[32px]">
                <button
                  className="h-[40px] w-[100%] rounded-[8px] bg-facebook text-[13px] font-[700] text-[#fff]"
                  onClick={() => loginWithFacebook()}
                >
                  <div className="flex flex-row items-center justify-start px-4">
                    <Image
                      src="/svgs/facebook.svg"
                      height={20}
                      width={21}
                      alt="search"
                      className="mr-[8px]"
                    />
                    <p>使用Facebook登入</p>
                    {lastLoginMethod === 'facebook' && <LogInMethodHighlight />}
                  </div>
                </button>
              </div>
              <div className="mt-[16px]">
                <button
                  className="h-[40px] w-[100%] rounded-[8px] border-[1px] border-[#9B9B9B] text-[14px] font-[700] text-sorra-gray-500"
                  onClick={() => loginWithGoogle()}
                >
                  <div className="flex flex-row items-center justify-start px-4">
                    <Image
                      src="/svgs/google.svg"
                      height={20}
                      width={21}
                      alt="search"
                      className="mr-[20px]"
                    />
                    <p>使用Google登入</p>
                    {lastLoginMethod === 'google' && <LogInMethodHighlight />}
                  </div>
                </button>
              </div>
              <div className="mt-[16px]">
                <button
                  className="h-[40px] w-[100%] rounded-[8px] border-[1px] border-sorra-pink text-[16px] font-[700] text-sorra-pink"
                  onClick={redirectEmailLogin}
                >
                  <div className="flex flex-row items-center justify-start px-4">
                    <Image
                      src="/svgs/mail.svg"
                      height={20}
                      width={20}
                      alt="search"
                      className="mr-[16px]"
                    />
                    <p>電郵登入/註冊</p>
                    {lastLoginMethod === 'email' && <LogInMethodHighlight />}
                  </div>
                </button>
              </div>
              <div className="mt-3 text-[14px] font-[400] text-[#9B1C1C]">
                {isLoginError ? (
                  <div className="flex flex-row items-center">
                    <Image
                      src="/svgs/error.svg"
                      width={16}
                      height={16}
                      alt=""
                      className="mr-[8px]"
                    />
                    {errorMsg}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </MobileModalContainer>
      </main>
    </div>
  );
}
